.radicalBtn {
	height: 30px;
	width: 30px;
	background: none;
	border: 1px solid #ccc;
	margin: 5px;
	border-radius: 3px;
	cursor: pointer;
	font-size: 16px;
}

.radicalActiveBtn {
	background: #61dafb;
	border-color: #4362a0;
}

.container {
	padding: 10px;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}
